<template>
  <v-app>
    <!--<div v-if="visivel === true">-->
    <core-filter />

    <core-toolbar />

    <core-drawer />

    <core-view />
    <!--</div> -->
    <!--<div v-else>
      <core-view />
    </div>-->
  </v-app>
</template>

<script>
export default {
  data: () => ({
    // visivel: null
  }),
  async beforeMount () {
    this.loginVerification()
  },
  methods: {
    loginVerification () {
      let value = localStorage.getItem('token')
      // console.log("TESTE=" + window.location.href.includes("login") + "-");
      if (value === 'null' && window.location.href !== '' && !(window.location.href).includes('login') && !window.location.href.includes('reset-password')) {
        // window.location.href = "http://localhost:8080/login";
        // window.location.href = "https://admin.emporiodasmarcas.com.br/login";
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/index.scss';

/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>
