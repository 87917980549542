import Vue from 'vue'

// Lib imports
import axios from 'axios'

//const API_URL = 'http://localhost:3000/projects'
const API_URL = 'https://api-store.emporiodasmarcas.com.br/projects';

var ax = axios.create({
  baseURL: API_URL
})

ax.interceptors.response.use(response => {
  return response
}, error => {
  if (error.toString().includes('401')) {
    // window.location.href = "https://admin.emporiodasmarcas.com.br/login2";
  }
  return Promise.reject(error.response.data)
})

ax.interceptors.request.use(async config => {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

Vue.prototype.$http = ax
