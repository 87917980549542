/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: '/home',
    name: 'Dashboard',
    view: 'Dashboard'
  },
  {
    path: '/user-profile',
    name: 'Perfil',
    view: 'UserProfile'
  },
  {
    path: '/produtos',
    name: 'Produtos',
    view: 'Produtos'
  },
  {
    path: '/realizarvenda',
    view: 'RealizarVenda'
  },
  {
    path: '/fornecedores',
    view: 'Fornecedores'
  },
  {
    path: '/usuarios',
    view: 'Usuarios'
  },
  {
    path: '/cadastrarproduto',
    view: 'CadastrarProduto'
  },
  {
    path: '/impressao',
    view: 'Impressao'
  },
  {
    path: '/politica-privacidade-seguranca',
    view: 'PoliticaPrivacidadeSeguranca'
  },
  {
    path: '/campanhas',
    view: 'Campanhas'
  },
  {
    path: '/propagandas',
    view: 'Propagandas'
  },
  {
    path: '/vendas',
    view: 'Vendas'
  },
  {
    path: '/mala',
    view: 'Mala'
  },
  {
    path: '/maps',
    view: 'Maps'
  },
  {
    path: '/Sair',
    view: 'Sair'
  },
  {
    path: '/upgrade',
    name: 'Upgrade to PRO',
    view: 'Upgrade'
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    view: 'ResetPasswordForm'
  },
  {
    path: '/',
    name: 'Login',
    view: 'Login'
  }
]
