<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    dark
    floating
    v-if="this.validador_drawer()"
    persistent
    mobile-break-point="991"
    width="260"
  >
      <v-layout class="fill-height" tag="v-list" column>
        <v-list-tile avatar>
          <v-list-tile-title class="title ml-5">Empório das Marcas</v-list-tile-title>
        </v-list-tile>
        <v-divider />
        <div v-if="this.tipo && this.tipo === 'administrador'">
          <v-list-tile
            v-for="(link, i) in links"
            :key="i"
            :to="link.to"
            :active-class="color"
            avatar
            class="v-list-item"
          >
            <v-list-tile-action>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-title v-text="link.text" />
          </v-list-tile>
        </div>
        <div v-else>
          <v-list-tile
            v-for="(link, i) in links_normaluser"
            :key="i"
            :to="link.to"
            :active-class="color"
            avatar
            class="v-list-item"
          >
            <v-list-tile-action>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-title v-text="link.text" />
          </v-list-tile>
        </div>
      </v-layout>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapMutations, mapState } from "vuex";

export default {
  props: {
    opened: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    logo: "favicon.ico",
    links: [
      {
        to: "/home",
        icon: "mdi-home",
        text: "Home"
      },
      {
        to: "/user-profile",
        icon: "mdi-account",
        text: "Informações"
      },
      {
        to: "/produtos",
        icon: "mdi-barcode",
        text: "Produtos"
      },
      {
        to: "/CadastrarProduto",
        icon: "mdi-plus",
        text: "Cadastrar Produto"
      },
      {
        to: "/realizarvenda",
        icon: "mdi-credit-card",
        text: "Realizar Venda"
      },
      {
        to: "/vendas",
        icon: "mdi-format-list-bulleted",
        text: "Vendas"
      },
      {
        to: "/fornecedores",
        icon: "mdi-account-multiple",
        text: "Fornecedores"
      },
      {
        to: "/usuarios",
        icon: "mdi-account-multiple",
        text: "Usuários"
      },
      {
        to: "/campanhas",
        icon: "mdi-folder-multiple",
        text: "Campanhas"
      },
      {
        to: "/propagandas",
        icon: "mdi-folder",
        text: "Propagandas"
      },
      {
        to: "/impressao",
        icon: "mdi-printer",
        text: "Impressão"
      },
      {
        to: "/mala",
        icon: "mdi-shopping",
        text: "Mala"
      },
      {
        to: "/maps",
        icon: "mdi-map-marker",
        text: "Mapa"
      },
      {
        to: "/Sair",
        icon: "mdi-exit-to-app",
        text: "Sair"
      }
    ],
    tipo: "normal",
    links_normaluser: [
      {
        to: "/home",
        icon: "mdi-home",
        text: "Home"
      },
      {
        to: "/produtos",
        icon: "mdi-barcode",
        text: "Produtos"
      },
      {
        to: "/realizarvenda",
        icon: "mdi-credit-card",
        text: "Realizar Venda"
      },
      {
        to: "/vendas",
        icon: "mdi-format-list-bulleted",
        text: "Vendas"
      },
      {
        to: "/CadastrarProduto",
        icon: "mdi-plus",
        text: "Cadastrar Produto"
      },
      {
        to: "/impressao",
        icon: "mdi-printer",
        text: "Impressão"
      },
      {
        to: "/Sair",
        icon: "mdi-exit-to-app",
        text: "Sair"
      }
    ]
  }),
  computed: {
    ...mapState("app", ["", "color"]),
    inputValue: {
      get() {
        return this.$store.state.app.drawer;
      },
      set(val) {
        this.setDrawer(val);
      }
    },
    items() {
      return this.$t("Layout.View.items");
    }
  },

  methods: {
    ...mapMutations("app", ["setDrawer", "toggleDrawer"]),
    validacao() {
      var usuario = JSON.parse(localStorage.getItem("user"));
      // console.log(usuario);
      if(usuario){
        this.tipo = usuario.tipo;
      }
      // console.log("TIPO = " + this.tipo);
      // console.log(this.$route.params);
      
    },
    validador_drawer(){
      // console.log("ENTROU");

      
      let path = this.$route.path;

      // console.log(path);


      if(path === "/login2" || path === "/" || path.includes("/reset-password/")){
        return false;
      } else {
        return true;
      }
    }
  },
  beforeMount() {
    this.validacao();
  }
};
</script>

<style lang="scss">
#app-drawer {
  .v-list__tile {
    border-radius: 4px;

    &--buy {
      margin-top: auto;
      margin-bottom: 17px;
    }
  }
}
</style>
